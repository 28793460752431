<template>
  <Dialog v-model:visible="display" header='添加自动化机械'
          @hide="$emit('close')" :modal="true">
    <div class="content">
      <!--      <div class="p-col colee">-->
      <!--        <InputText placeholder="请输入机械名称" class="rig"></InputText>-->
      <!--        <InputText placeholder="请输入厂商名称" class="rig"></InputText>-->
      <!--        <Dropdown class="rig" v-model="pingz" :options="jiagun" optionLabel="name"/>-->
      <!--        <Button style="width: auto;">搜索</Button>-->
      <!--      </div>-->
      <DataTable :value="list" :scrollable="true" :row-hover="true" v-model:selection="selectedCar">
        <template #empty>
          没有发现数据
        </template>
        <column selectionMode="multiple"></column>
        <Column field="name" header="机械名称"></Column>
        <Column field="type" header="分类"></Column>
        <Column field="brand" header="品牌"></Column>
        <Column field="mfr" header="厂家/供应商/渠道"></Column>
        <Column field="modal" header="型号"></Column>
        <template #paginatorLeft></template>
      </DataTable>
      <Paginator :rows="page.size" :totalRecords="page.total"
                 @page="changePage"
                 template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
                 :rowsPerPageOptions="[10,20,50,100]"
                 currentPageReportTemplate="共{totalRecords} 条记录">
        <template #right>
          <label>跳至&nbsp;&nbsp;</label>
          <InputNumber v-model="page.current" @keydown.enter="getList" v-tooltip.focus="'按下回车键后跳转'"
                       inputStyle="width: 40px;"/>
          <label>&nbsp;&nbsp;页</label>
        </template>
      </Paginator>
    </div>
    <template #footer>
      <Button @click="submit">确认选择</Button>
    </template>
  </Dialog>
</template>


<script>

export default {
  name: "addOrUpdatesuggest2",
  data() {
    return {
      display: false,
      list: [],
      pingz: '',
      jiagun: [
        {id: 0, name: '种植机械'},
        {id: 1, name: '植物保护机'},
        {id: 2, name: '无人机'},
        {id: 3, name: '灌溉设备'},
        {id: 4, name: '收割'},
        {id: 5, name: '全部'},
      ],
      page: {
        size: 10,
        total: '',
        current: 1,
      },
      selectedCar: null,
      selectedItems: null
    }
  },

  methods: {
    getList() {
      let m = this
      this.$http('/machine/getList', 'get', {
        "current": this.page.current, "size": this.page.size,
      }, function (res) {
        m.page.total = res.data.total
        m.list = res.data.records
        if (m.selectedItems) {
          m.selectedCar = res.data.records.filter(item => {
            return m.selectedItems.indexOf(item.id) > -1
          })
        }
      })
    },
    init(data) {
      this.display = true
      if (data && data.length > 0) {
        this.selectedItems = []
        data.forEach(item => {
          this.selectedItems.push(item.id)
        })
      }
      this.getList()
    },
    changePage(page) {
      this.page.size = page.rows
      this.page.current = page.page + 1
      this.getList()
    },
    submit() {
      let val = this.selectedCar
      this.$emit("say2", val)
      this.display = false
    }
  }
}
</script>

<style scoped>
.content {
  width: 50vw;
  height: 50vh;
}

.colee {
  display: flex;
  justify-content: flex-start;
}

::v-deep(.p-dialog .p-dialog-content) {
  padding-top: 0;
}

.rig {
  margin-right: 2vw;
}

</style>
